const $backToTopButton = document.querySelector('[data-scroll-to-top]');

if($backToTopButton) {
    let ticking = false;
    document.addEventListener('scroll', () => {
        if(!ticking) {
            window.requestAnimationFrame(() => {
                if(window.scrollY > 200) {
                    $backToTopButton.classList.add('scroll-to-top-button--active');
                } else {
                    $backToTopButton.classList.remove('scroll-to-top-button--active');
                }
                ticking = false;
            });
            ticking = true;
        }
    });
    
    $backToTopButton.addEventListener('click', () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    });
}
